<script lang="ts" setup>
const getInvalidClass = (invalid: boolean | undefined) => {
	if (invalid === undefined) {
		return "";
	}
	return invalid ? "invalid" : "valid";
};

const props = withDefaults(
	defineProps<{
		modelValue?: boolean;
		invalid?: boolean;
		noAnimation?: boolean;
		disabled?: boolean;
		variant?: "default" | "small";
	}>(),
	{ modelValue: false, invalid: undefined, noAnimation: false, disabled: false }
);

const emit = defineEmits<{ (event: "update:modelValue", value: boolean): void }>();

const pulseColor = computed(() =>
	props.invalid ? "var(--m-checkbox-default-pulse-color)" : "var(--a-checkbox-border)"
);
</script>

<template>
	<label>
		<AAnimationPulse
			:width="8"
			:duration="3"
			:color="pulseColor"
			:active="(!modelValue || invalid) && !noAnimation"
			timing-function="ease-out"
		>
			<ACheckbox
				:class="getInvalidClass(invalid)"
				:checked="modelValue"
				:variant="variant"
				:disabled="disabled"
				@click="emit('update:modelValue', ($event.target as HTMLInputElement).checked)"
			/>
		</AAnimationPulse>
		<slot />
	</label>
</template>
<style lang="scss">
:root {
	--m-checkbox-default-pulse-color: var(--camaguey);
}

@if mixin-exists(MCheckbox) {
	@include MCheckbox;
}
</style>
<style lang="scss" scoped>
label {
	display: flex;
	gap: gutter(1);
	cursor: pointer;
	line-height: 1.5;
}
</style>
